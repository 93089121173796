import { _HasDataLoaderMeta } from 'unplugin-vue-router/runtime'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/opt/buildhome/repo/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/opt/buildhome/repo/src/pages/[...all].vue'),
    /* no children */
  },
  {
    path: '/app',
    name: '/app',
    component: () => import('/opt/buildhome/repo/src/pages/app.vue'),
    children: [
      {
        path: '',
        name: '/app/',
        component: () => import('/opt/buildhome/repo/src/pages/app/index.vue'),
        /* no children */
      },
      {
        path: 'mentions',
        /* internal name: '/app/mentions' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/mentions/',
            component: () => import('/opt/buildhome/repo/src/pages/app/mentions/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            /* internal name: '/app/mentions/[id]' */
            /* no component */
            children: [
              {
                path: '',
                name: '/app/mentions/[id]/',
                component: () => import('/opt/buildhome/repo/src/pages/app/mentions/[id]/index.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'profile',
        name: '/app/profile',
        component: () => import('/opt/buildhome/repo/src/pages/app/profile.vue'),
        /* no children */
      },
      {
        path: 'rotations',
        /* internal name: '/app/rotations' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/rotations/',
            component: () => import('/opt/buildhome/repo/src/pages/app/rotations/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            /* internal name: '/app/rotations/[id]' */
            /* no component */
            children: [
              {
                path: '',
                name: '/app/rotations/[id]/',
                component: () => import('/opt/buildhome/repo/src/pages/app/rotations/[id]/index.vue'),
                /* no children */
              },
              {
                path: 'customize',
                name: '/app/rotations/[id]/customize',
                component: () => import('/opt/buildhome/repo/src/pages/app/rotations/[id]/customize.vue'),
                /* no children */
              },
              {
                path: 'options',
                name: '/app/rotations/[id]/options',
                component: () => import('/opt/buildhome/repo/src/pages/app/rotations/[id]/options.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'settings',
        /* internal name: '/app/settings' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/settings/',
            component: () => import('/opt/buildhome/repo/src/pages/app/settings/index.vue'),
            /* no children */
          },
          {
            path: 'billing',
            name: '/app/settings/billing',
            component: () => import('/opt/buildhome/repo/src/pages/app/settings/billing.vue'),
            /* no children */
          },
          {
            path: 'integrations',
            name: '/app/settings/integrations',
            component: () => import('/opt/buildhome/repo/src/pages/app/settings/integrations.vue'),
            /* no children */
          },
          {
            path: 'rotations',
            name: '/app/settings/rotations',
            component: () => import('/opt/buildhome/repo/src/pages/app/settings/rotations.vue'),
            /* no children */
            meta: {
              "requiresPrivilegedRole": true
            }
          },
          {
            path: 'status-history',
            /* internal name: '/app/settings/status-history' */
            /* no component */
            children: [
              {
                path: '',
                name: '/app/settings/status-history/',
                component: () => import('/opt/buildhome/repo/src/pages/app/settings/status-history/index.vue'),
                /* no children */
                meta: {
                  "requiresPrivilegedRole": true,
                  "requiresPaidPlan": true
                }
              },
              {
                path: ':id',
                /* internal name: '/app/settings/status-history/[id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/app/settings/status-history/[id]/',
                    component: () => import('/opt/buildhome/repo/src/pages/app/settings/status-history/[id]/index.vue'),
                    /* no children */
                    meta: {
                      "requiresPrivilegedRole": true,
                      "requiresPaidPlan": true
                    }
                  }
                ],
              }
            ],
          },
          {
            path: 'statuses',
            name: '/app/settings/statuses',
            component: () => import('/opt/buildhome/repo/src/pages/app/settings/statuses.vue'),
            /* no children */
            meta: {
              "requiresPrivilegedRole": true,
              "requiresPaidPlan": true
            }
          },
          {
            path: 'team',
            name: '/app/settings/team',
            component: () => import('/opt/buildhome/repo/src/pages/app/settings/team.vue'),
            /* no children */
            meta: {
              "requiresPrivilegedRole": true
            }
          },
          {
            path: 'user',
            name: '/app/settings/user',
            component: () => import('/opt/buildhome/repo/src/pages/app/settings/user.vue'),
            /* no children */
          },
          {
            path: 'users',
            name: '/app/settings/users',
            component: () => import('/opt/buildhome/repo/src/pages/app/settings/users.vue'),
            /* no children */
            meta: {
              "requiresPrivilegedRole": true
            }
          }
        ],
      }
    ],
    meta: {
      "requiresAuth": true,
      "requiresSlackIdentity": true,
      "requiresConfig": true
    }
  },
  {
    path: '/auth',
    name: '/auth',
    component: () => import('/opt/buildhome/repo/src/pages/auth.vue'),
    children: [
      {
        path: 'google',
        /* internal name: '/auth/google' */
        /* no component */
        children: [
          {
            path: 'callback',
            name: '/auth/google/callback',
            component: () => import('/opt/buildhome/repo/src/pages/auth/google/callback.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'login-callback',
        name: '/auth/login-callback',
        component: () => import('/opt/buildhome/repo/src/pages/auth/login-callback.vue'),
        /* no children */
      },
      {
        path: 'logout',
        name: '/auth/logout',
        component: () => import('/opt/buildhome/repo/src/pages/auth/logout.vue'),
        /* no children */
      },
      {
        path: 'pagerduty',
        /* internal name: '/auth/pagerduty' */
        /* no component */
        children: [
          {
            path: 'callback',
            name: '/auth/pagerduty/callback',
            component: () => import('/opt/buildhome/repo/src/pages/auth/pagerduty/callback.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'slack',
        /* internal name: '/auth/slack' */
        /* no component */
        children: [
          {
            path: '',
            name: '/auth/slack/',
            component: () => import('/opt/buildhome/repo/src/pages/auth/slack/index.vue'),
            /* no children */
          },
          {
            path: 'callback',
            name: '/auth/slack/callback',
            component: () => import('/opt/buildhome/repo/src/pages/auth/slack/callback.vue'),
            /* no children */
          }
        ],
      }
    ],
    meta: {
      "requiresConfig": true
    }
  },
  {
    path: '/checkout',
    name: '/checkout',
    component: () => import('/opt/buildhome/repo/src/pages/checkout.vue'),
    children: [
      {
        path: 'success',
        name: '/checkout/success',
        component: () => import('/opt/buildhome/repo/src/pages/checkout/success.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/cookie-policy',
    name: '/cookie-policy',
    component: () => import('/opt/buildhome/repo/src/pages/cookie-policy.vue'),
    /* no children */
  },
  {
    path: '/error',
    /* internal name: '/error' */
    /* no component */
    children: [
      {
        path: '404',
        name: '/error/404',
        component: () => import('/opt/buildhome/repo/src/pages/error/404.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/privacy',
    name: '/privacy',
    component: () => import('/opt/buildhome/repo/src/pages/privacy.vue'),
    /* no children */
  },
  {
    path: '/security',
    name: '/security',
    component: () => import('/opt/buildhome/repo/src/pages/security.vue'),
    /* no children */
  },
  {
    path: '/terms-of-service',
    name: '/terms-of-service',
    component: () => import('/opt/buildhome/repo/src/pages/terms-of-service.vue'),
    /* no children */
  }
]
